import React from "react"
import { connect } from "react-redux"

import SEO from "src/components/seo"
import Layout from "src/components/layout"
import GradientsNew from "src/components/gradients-new"
import TopMenu from "src/components/top_menu"
import SubpageLayout from "src/components/subpage_layout"
import SubpageFirstScreen from "src/components/subpage_first_screen"
import PriceList from "src/components/prices_list"
import Footer from "src/components/footer"
import MobileMenu from "src/components/mobile_menu"

import divisions from "src/content/divisions"

import subpageStyles from "src/css/subpage.module.css"

import brandImg from "src/images/pages/branding/image.png"
import tshirtPage from "src/images/tshirt-page.png"
import gadzetyblockimg from "src/images/pages/gadgets/brudnadzielna.jpg"
import brelok from "src/images/pages/gadgets/brelok-2.jpg"
import czapka from "src/images/pages/gadgets/czapka.jpg"
import tshirt from "src/images/pages/gadgets/tshirt.jpg"
import ramka from "src/images/pages/gadgets/ramka.jpg"
import usb from "src/images/pages/gadgets/usb.jpg"


const Gadgets = ({ isMobile }) => (
  <Layout>
    <SEO title="Gadżety - Departament Reklamy" keywords={[`departament reklamy`, `elo`, `react`]} lang="pl"/>
    <GradientsNew activeGradient={2} iconBg={divisions.gadgets.icon}/>
    {isMobile.isMobile ? (
      <MobileMenu homeBack={true}/>
    ) : (
      <TopMenu homeBack={true}/>
    )}

    <SubpageLayout>
      <SubpageFirstScreen content={{
        title: "GADŻETY REKLAMOWE",
        sitemap: [
          { title: "Opis", id: "id" },
          { title: "Najczęściej wybierane", id: "najczesciej-wybierane" },
          { title: "Cennik", id: "cennik" },
        ],
        slogan: "Wyznaczaj styl własną firmą",
        img: tshirtPage,
        texts: {
          1: {
            title: "ROZPOZNAWALNE PRODUKTY - ROZPOZNAWALNA MARKA",
            desc: "W dzisiejszych czasach ubiór stał się sposobem na wyrażenie siebie, " +
            "przynależności do danej subkultury lub po prostu własnego gustu. Dzięki nowoczesnym rozwiązaniom, " +
            "jak zaawansowane prasy drukarskie, możemy przenieść dowolny projekt na koszulki, kubki, czy inne akcesoria.",
          },
          2: {
            title: "ZAPEWNIAMY NAJWYŻSZY POZIOM WYKONANIA",
            desc: "Każdy produkt jest wykonany z największą starannością, w dbałości o szczegóły.",
          },
        },
      }}/>
      <div
        className={[subpageStyles.subpage_block, subpageStyles.white, subpageStyles.title_block, subpageStyles.items_block].join(" ")}
      id="najczesciej-wybierane">
        <div className={subpageStyles.title}>
          Najczęściej wybierane
        </div>
        <div className={subpageStyles.items}>
          <div className={subpageStyles.item}>
            <div className={subpageStyles.item_image}>
              <img src={tshirt} />
            </div>
            <div className={subpageStyles.item_title}>Koszulka z nadrukiem</div>
          </div>
          <div className={subpageStyles.item}>
            <div className={subpageStyles.item_image}>
              <img src={czapka} />
            </div>
            <div className={subpageStyles.item_title}>Czapka z nadrukiem</div>
          </div>
          <div className={subpageStyles.item}>
            <div className={subpageStyles.item_image}>
              <img src={brelok} />
            </div>
            <div className={subpageStyles.item_title}>Brelok z nadrukiem</div>
          </div>
          <div className={subpageStyles.item}>
            <div className={subpageStyles.item_image}>
              <img src={ramka} />
            </div>
            <div className={subpageStyles.item_title}>Ramka pod rejestrację</div>
          </div>
          <div className={subpageStyles.item}>
            <div className={subpageStyles.item_image}>
              <img src={usb} />
            </div>
            <div className={subpageStyles.item_title}>Pendrive z nadrukiem</div>
          </div>
        </div>
      </div>
      <div className={subpageStyles.subpage_block} style={{
        height: "20vh",
      }}/>
      <div className={[subpageStyles.subpage_block, subpageStyles.image_block].join(" ")}>
        <img src={gadzetyblockimg}/>
      </div>
      <div className={subpageStyles.subpage_block} style={{
        height: "20vh",
      }}/>
      <PriceList id="cennik" content={{
        items: [
          { title: "Koszulka z nadrukiem", desc: "Logo 10cm x 10cm, nakład 50 szt.", price: "35 zł", prefix: "od" },
          { title: "Brelok reklamowy", desc: "nadrukowane logo", price: "20 zł", prefix: "od" },
        ],
        suggestions: [divisions.papers],
      }}/>
      {isMobile.isMobile ? null : (
        <Footer/>
      )}
    </SubpageLayout>
  </Layout>
)

export default connect(state => ({
  isMobile: state.isMobile,
}), null)(Gadgets)
